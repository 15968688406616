<template>
  <!-- eslint-disable max-len -->
  <section class="Page SupportPage">
    <h2 class="Title Title--type-h2 Page-Title SupportPage-Title">
      <span class="Colored">{{ $t('supportPage.title') }}</span>
    </h2>
    <div class="Page-Section SupportPage-Section">
      <h3>
        {{ $t('supportPage.email.title') }}
      </h3>
      <p>
        {{ $t('supportPage.email.text') }}
      </p>
      <p>
        {{ $t('supportPage.email.toDo') }}: <a :href="`mailto:${$t('supportMail') }`">{{ $t('supportMail') }}</a>
      </p>
    </div>
    <div class="Page-Section SupportPage-Section">
      <h3>
        {{ $t('supportPage.chat.title') }}
      </h3>
      <p>
        {{ $t('supportPage.chat.text') }}
      </p>
      <p>
        {{ $t('supportPage.chat.toDo') }}
      </p>
    </div>
</section>
</template>

<script>
export default {
  name: 'SupportPage',
  metaInfo() {
    return {
      title: this.$t('metaInfo.support.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.support.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.SupportPage {
  &-Section {
    margin-bottom: 10px;
  }

  &-Title {
    margin-bottom: 30px;
    padding-left: 20px;

    @media (min-width: $screen-m) {
      margin-bottom: 40px;
      padding-left: 0;
    }

    @media (min-width: $screen-l) {
      margin-bottom: 50px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 60px;
    }
  }
}

</style>
